import 'jquery-ui/ui/widgets/datepicker';

const mainHelper = {
    renderNotificationTicker: function (){
      if(page == 'admin'){
        $('.notification-container').css('display', 'none');
      }
      if($('.notification-container').length > 0){
        $('.notification-container').css('display', 'block');
        $('.main-container').css('top', '175px').css('min-height', 'calc(100vh - 175px)')
        if(page == 'News' || page == 'Home'){
          $('.notification-container').css('top', '48px');
        }
      }
    }
}

let copyRightTextDom = $('#copyright-text');
let copyRightText = copyRightTextDom.text();
let date = new Date();
let year = date.getFullYear();

copyRightText = copyRightText.replace('year', year);
copyRightTextDom.text(copyRightText);


$(function(){
    mainHelper.renderNotificationTicker();    
});